<template>
  <div class="purchase-detail-step-info">
    <div class="step" v-for="(item, index) in stepList" :key="index">
      <div class="top">
        <div class="line" :class="stepClass[index + '_left']">
        </div>
        <div class="line" :class="stepClass[index + '_right']"></div>
        <div class="point">
          <div
            class="point-d"
            :class="stepClass[index]"></div>
        </div>
      </div>
      <div class="bottom">{{item.statusText}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStatus: { type: String },
    recodeList: { type: Array, defualt: () => [] },
    config: { type: Array, defualt: () => [] },
    statusMap: { type: Object, defualt: () => {} }
  },
  data () {
    return {
      isError: false,
      error: ''
    }
  },
  computed: {
    stepClass () {
      let data = {}
      let i = 0
      while (i < this.stepList.length) {
        let v = this.stepList[i]
        let classKey = v.status === 'invalid' ? 'invalid' : v.type
        if (v.status === 'end' && v.type === 'current') {
          classKey = 'dealed'
        }
        data[i] = {}
        data[i][classKey] = true
        data[i + '_left'] = {}
        data[i + '_left'][classKey] = true
        if (i > 0) {
          data[(i - 1) + '_right'] = {}
          data[(i - 1) + '_right'][classKey] = true
        }
        if (i === 0) {
          data[i + '_left'] = {'tm-line': true}
        }
        if (i === this.stepList.length - 1) {
          data[i + '_right'] = {'tm-line': true}
        }
        i ++
      }
      return data
    },
    stepList () {
      let data = []
      if (!this.config || this.config.length === 0 || !this.currentStatus)  {
        return data
      }

      let recodeList = this.recodeList.filter(v => !v.isBack)
      let i = 0
      while (i < recodeList.length) {
        let v = recodeList[i]
        data.push({
          status: v.statusKey,
          statusText: this.statusMap[v.statusKey],
          type: 'dealed'
        })
        i ++
      }
      data.push({
        status: this.currentStatus,
        statusText: this.statusMap[this.currentStatus],
        type: 'current'
      })
      i = 0
      let find = false
      while (i < this.config.length) {
        let v = this.config[i]
        if (!find) {
          find = v.statusKey === data[data.length - 1].status
        }
        if (find) {
          data.push({
            status: v.newStatusKey,
            statusText: this.statusMap[v.newStatusKey],
            type: 'wait'
          })
        }
        i ++
      }
      return data
    }
  }
}
</script>

<style scoped lang="less">
@wait-color: #e8eaec;
@dealed-color: #3fabf4;
@current-color: #3cbf50;
@warning-color: #ec8b1c;
.purchase-detail-step-info {
   display: flex;
   margin: 10px 0;
   .step {
     height: 100px;
     min-width: 65px;
     .top {
       display: flex;
       align-items: center;
       height: 50%;
       position: relative;
       .line {
         width: 50%;
         height: 1.5px;
         background: @wait-color;
       }
       .tm-line {
         background: rgba(0,0,0,0) !important;
       }
       .point {
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         .point-d {
           width: 10px;
           height: 10px;
           border-radius: 10px;
           background: @wait-color;
         }
       }
    }
    .current {
      background: @current-color !important;
    }
    .dealed {
      background: @dealed-color !important;
    }
    .invalid {
      background: @warning-color !important;
    }
     .bottom {
       display: flex;
       color: #515a6e;
       padding: 0 10px;
       height: 50%;
       text-align: center;
     }
   }
}
</style>